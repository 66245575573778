import React from 'react'
import SEO from '../../components/SEO'
import WorkSlider from '../../components/workSlider'

const WorkPage = ({ pageContext }) => {
	const {
		page: { title, workArchive },
	} = pageContext

	return (
		<>
			<SEO title={title} />
			<WorkSlider projects={workArchive} />
		</>
	)
}

export default WorkPage
